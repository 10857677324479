import { usePrintHelper } from "@/composables/usePrintHelper";
import type { IndexedDBSchemas } from "@/composables/useIndexDB";

export type TerminalSchema = IndexedDBSchemas["terminal"];

interface PrinterType {
  name: string;
  displayName: string;
  description: string;
  status: number;
  isDefault: boolean;
  options: {
    "printer-location": string;
    "printer-make-and-model": string;
    system_driverinfo: string;
  };
}

const INIT_TERMINAL_OPTIONS: TerminalSchema = {
  printers: [],
  callNumbersEnabled: false,
  showProductsBarcode: false,
  showProductsTotalQuantity: false,
  showReferenceBarcode: false,
  locale: "ar",
  electronicScaleBarcode: {
    enabled: false,
    productSegmentLength: 1,
    weightSegmentLength: 1,
    weightRatio: 1,
  },
};

export const useTerminalStore = defineStore("terminal", () => {
  // states
  const printersList = ref<PrinterType[]>([]);
  const callNumbersCount = ref(0);
  const isBarcodeMode = ref(true);
  const isSaving = ref(false);

  const options = ref<TerminalSchema>(INIT_TERMINAL_OPTIONS);

  // composables
  const { cache } = useIndexDB();
  const { url: printHelperUrl } = usePrintHelper();

  // saveOptions
  const saveOptions = async () => {
    isSaving.value = true;
    try {
      await cache.terminal.save("1", JSON.parse(JSON.stringify(options.value)));
    } catch (error) {
      console.error("Error saving options:", error);
    } finally {
      isSaving.value = false;
    }
  };

  // fetchPrinterList
  const fetchPrinterList = async () => {
    if (!printHelperUrl.value) return;
    try {
      const { data, error } = await useFetch<PrinterType[]>(
        `${printHelperUrl.value}/printers`
      );

      if (error.value || !data.value) {
        throw error.value || new Error("no data");
      }

      printersList.value = data.value;
    } catch (error) {
      console.error("Error fetching printer list:", error);
    }
  };

  // togglePrinter
  const togglePrinter = async (printerName: string) => {
    const cloned = [...options.value.printers];
    const index = cloned.findIndex(({ name }) => name === printerName);

    if (index > -1) {
      cloned.splice(index, 1);
    } else {
      cloned.push({ name: printerName, copies: 1 });
    }

    options.value.printers = cloned;
  };

  // incrementCallNumber
  const incrementCallNumber = () => {
    const edited = callNumbersCount.value + 1;
    callNumbersCount.value = edited;

    return edited;
  };

  // resetCounter
  const resetCounter = () => {
    callNumbersCount.value = 0;
  };

  // init
  const init = async () => {
    const [, terminalData] = await Promise.allSettled([
      fetchPrinterList(),
      cache.terminal.get(),
    ]);

    if (terminalData.status === "fulfilled") {
      if (!terminalData.value) {
        await cache.terminal.save("1", INIT_TERMINAL_OPTIONS);
      }

      if (terminalData.value) {
        options.value = {
          ...INIT_TERMINAL_OPTIONS,
          ...JSON.parse(JSON.stringify(terminalData.value)),
        };
      } else {
        options.value = INIT_TERMINAL_OPTIONS;
      }

      const defaultPrinter = printersList.value.find(
        (printer) => printer.isDefault
      );

      if (defaultPrinter && !options.value.printers.length) {
        options.value.printers.push({ name: defaultPrinter.name, copies: 1 });
      }
    }
  };

  // clear
  const clear = async () => {
    await cache.terminal.clear();
    printersList.value = [];
    callNumbersCount.value = 0;
    isBarcodeMode.value = true;

    options.value = INIT_TERMINAL_OPTIONS;
  };

  return {
    // getters
    printersList,
    callNumbersCount,
    isBarcodeMode,
    options,
    isSaving,
    // setters
    init,
    clear,
    togglePrinter,
    incrementCallNumber,
    resetCounter,
    fetchPrinterList,
    saveOptions,
  };
});
