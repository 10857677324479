import { useUtils } from "~/composables/useUtils";
import { useSettingsStore } from "./settings";

export type ProductSchema = APIOutputs["products"]["getAllPOS"]["data"][number];
export type ProductCategorySchema =
  APIOutputs["productCategories"]["getAllPOS"]["data"][number];

const { isNetworkError } = useUtils();

export type PricingPoliciesProduct = NonNullable<
  APIOutputs["salesPricingPolicies"]["getOneActive"]["pricingPolicyProducts"]
>[number];

export const useProductStore = defineStore("product", () => {
  // states
  const products = ref<ProductSchema[]>([]);
  const productCategories = ref<ProductCategorySchema[]>([]);
  const pricingPoliciesProducts = ref<PricingPoliciesProduct[] | null>([]);

  const lastFetchedAt = ref<number>();

  // composables
  const { cache } = useIndexDB();

  // fetchData handler
  const fetchData = async () => {
    const { trpcClient } = useTrpcClient();
    const { settings } = useSettingsStore();
    const { logout } = useAuthStore();

    let fetchedData:
      | {
          products: ProductSchema[];
          productCategories: ProductCategorySchema[];
          pricingPoliciesProducts: PricingPoliciesProduct[];
        }
      | undefined;

    try {
      if (!settings) {
        throw new Error("Settings not found");
      }

      const [products, productCategories, pricingPolicy] = await Promise.all([
        trpcClient.products.getAllPOS.query(),
        trpcClient.productCategories.getAllPOS.query(),
        // handle the case where the pricing policy is disabled
        trpcClient.salesPricingPolicies.getOneActive
          .query({
            where: { posId: settings.posId },
          })
          .catch(() => {
            console.debug("Pricing policy disabled!");
            return null;
          }),
      ]);

      const pricingPolicyProducts = pricingPolicy?.pricingPolicyProducts ?? [];
      // cache data
      await Promise.all([
        cache.products.saveMany(products.data),
        cache.categories.saveMany(productCategories.data),
        cache.pricingPoliciesProducts.saveMany(pricingPolicyProducts),
      ]);

      fetchedData = {
        products: products.data,
        productCategories: productCategories.data,
        pricingPoliciesProducts: pricingPolicyProducts,
      };

      lastFetchedAt.value = Date.now();
    } catch (error) {
      if (isNetworkError(error)) {
        // if network error, use cached data
        const [
          cachedProducts,
          cachedProductCategories,
          cachedPricingPoliciesProducts,
        ] = await Promise.all([
          cache.products.getAll(),
          cache.categories.getAll(),
          cache.pricingPoliciesProducts.getAll(),
        ]);

        if (
          cachedProducts &&
          cachedProductCategories &&
          cachedPricingPoliciesProducts
        ) {
          fetchedData = {
            products: cachedProducts,
            productCategories: cachedProductCategories,
            pricingPoliciesProducts: cachedPricingPoliciesProducts,
          };
        }
      } else {
        logout();
      }
    } finally {
      // if data is available, set it
      if (fetchedData) {
        products.value = fetchedData.products;
        productCategories.value = fetchedData.productCategories;
        pricingPoliciesProducts.value = fetchedData.pricingPoliciesProducts;
      }
    }
  };

  const getDefaultPrices = ({
    productId,
    salePrice,
  }: {
    productId: string;
    salePrice: number;
  }) => {
    const pricingPolicyProduct = pricingPoliciesProducts.value?.find(
      (p) => p.productId === productId
    );

    const originalPrice = pricingPolicyProduct?.originalPrice ?? salePrice;
    const actualPrice = pricingPolicyProduct?.actualPrice ?? salePrice;

    return {
      originalPrice,
      actualPrice,
    };
  };

  const getProductPrices = (product: {
    originalUnitPrice: number;
    actualUnitPrice: number;
    quantity: number;
    taxPercent: number;
    taxIsIncluded: boolean;
  }) => {
    const {
      originalUnitPrice,
      actualUnitPrice,
      quantity,
      taxPercent,
      taxIsIncluded,
    } = product;

    const { Decimal, parseCurrency: currencyParserTotals } = useCurrency();
    const { parseCurrency: currencyParserUnits } = useCurrency({
      extraPrecision: true,
    });

    const originalUnitPriceParsed = currencyParserUnits(originalUnitPrice);
    const actualUnitPriceParsed = currencyParserUnits(actualUnitPrice);

    const taxPercentAsMultiplier = new Decimal(taxPercent).div(100);

    let originalPriceSubtotal = currencyParserTotals(
      new Decimal(originalUnitPriceParsed).times(quantity)
    );
    let originalTaxSubtotal = currencyParserTotals(
      new Decimal(originalUnitPriceParsed)
        .times(quantity)
        .times(taxPercentAsMultiplier)
    );

    let actualPriceSubtotal = currencyParserTotals(
      new Decimal(actualUnitPriceParsed).times(quantity)
    );
    let taxSubtotal = currencyParserTotals(
      new Decimal(actualUnitPriceParsed)
        .times(quantity)
        .times(taxPercentAsMultiplier)
    );

    if (taxIsIncluded) {
      const originalSubtotal = originalPriceSubtotal;
      const actualSubtotal = actualPriceSubtotal;

      originalPriceSubtotal = currencyParserTotals(
        new Decimal(originalPriceSubtotal).div(taxPercentAsMultiplier.add(1))
      );
      originalTaxSubtotal = currencyParserTotals(
        new Decimal(originalSubtotal).div(taxPercentAsMultiplier.add(1))
      );

      actualPriceSubtotal = currencyParserTotals(
        new Decimal(actualPriceSubtotal).div(taxPercentAsMultiplier.add(1))
      );
      taxSubtotal = new Decimal(actualSubtotal)
        .minus(actualPriceSubtotal)
        .toNumber();
    }

    return {
      originalUnitPrice: originalUnitPriceParsed,
      actualUnitPrice: actualUnitPriceParsed,
      originalPriceSubtotal,
      originalTaxSubtotal,
      actualPriceSubtotal,
      taxSubtotal,
    };
  };

  // clear handler
  const clear = async () => {
    await Promise.all([
      cache.products.clear(),
      cache.categories.clear(),
      cache.pricingPoliciesProducts.clear(),
    ]);
    products.value = [];
    productCategories.value = [];
    pricingPoliciesProducts.value = [];
  };

  // init
  const init = async () => {
    await fetchData();
  };

  return {
    // getters
    products,
    productCategories,
    pricingPoliciesProducts,
    lastFetchedAt,
    // setters
    init,
    clear,
    fetchData,
    getDefaultPrices,
    getProductPrices,
  };
});
