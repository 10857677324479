import type { OrganizationAccessRoles } from "@mono/validation/lib/Organization";
import type {
  SalesInvoicesInsertPOSInputSchema,
  SalesInvoiceProductsInsertInputSchema,
  SalesReceiptInvoiceUsagesReceiptPOSInsertInputSchema,
} from "@mono/validation/lib/Sales";
import type { PosSchema } from "@/stores/pos";
import type {
  ProductCategorySchema,
  PricingPoliciesProduct,
} from "@/stores/product";
import type { PendingContactsSchema, ContactSchema } from "@/stores/contact";

type PendingSync = {
  isNotSynced?: boolean;
};

export type Invoice = Omit<
  SalesInvoicesInsertPOSInputSchema,
  "salesReceiptInvoiceUsages" | "salesInvoiceProducts"
> & {
  salesInvoiceProducts: (SalesInvoiceProductsInsertInputSchema & {
    taxPercent: number;
  })[];
  salesReceiptInvoiceUsages?: (SalesReceiptInvoiceUsagesReceiptPOSInsertInputSchema & {
    actualPaidAmount: number;
  })[];
} & {
  callNumber?: number;
  isAnonymousCustomer?: boolean;
  memberId?: string;
} & PendingSync;

export interface IndexedDBSchemas {
  users: {
    id: string;
    email: string;
    fullName: string;
    refreshToken: string;
    pinCode: string;
    roles: {
      orgId: string;
      memberId: string | null;
      isOwner: boolean;
      isManager: boolean;
      hasPosAccess: boolean;
      memberRoles:
        | {
            accessRules: OrganizationAccessRoles;
          }[]
        | null;
    }[];
  };
  settings: {
    posId: string;
    orgId: string;
    lockedAt: string;
  };
  pos: PosSchema;
  products: ProductSchema;
  productCategories: ProductCategorySchema;
  contacts: ContactSchema;
  pendingContacts: PendingContactsSchema;
  invoices: Invoice;
  pendingInvoices: Invoice;
  terminal: {
    printers: {
      name: string;
      copies: number;
    }[];
    callNumbersEnabled: boolean;
    showProductsBarcode: boolean;
    showProductsTotalQuantity: boolean;
    showReferenceBarcode: boolean;
    locale: string;
    electronicScaleBarcode: {
      enabled: boolean;
      productSegmentLength: number;
      weightSegmentLength: number;
      weightRatio: number;
    };
  };
  dumpInvoices: { posId: string; invoices: Invoice[] };
  pricingPoliciesProducts: PricingPoliciesProduct;
}

export type SchemaKeys = keyof IndexedDBSchemas;

class Cache<T extends SchemaKeys> {
  private static instances: Record<string, Cache<any>> = {};
  private key: T;
  private db: IDBDatabase;

  private constructor(key: T, database: IDBDatabase) {
    this.key = key;
    this.db = database;
  }

  static getInstance<T extends SchemaKeys>(
    key: T,
    database: IDBDatabase
  ): Cache<T> {
    if (!Cache.instances[key]) {
      Cache.instances[key] = new Cache(key, database);
    }
    return Cache.instances[key] as Cache<T>;
  }

  static onRequest(request: IDBRequest) {
    return new Promise((resolve, reject) => {
      request.onsuccess = (event) => resolve(event);
      request.onerror = (event) => reject(event);
    });
  }

  async saveMany(data: IndexedDBSchemas[T][]) {
    const objectStore = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key);

    const requests = data.map((item) => {
      return objectStore.put(JSON.parse(JSON.stringify(item)));
    });

    await Promise.all(requests.map((req) => Cache.onRequest(req)));

    return requests.map((req) => req.result) as string[];
  }

  async add(data: IndexedDBSchemas[T]) {
    const request = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key)
      .add(data);

    await Cache.onRequest(request);

    return request.result as string;
  }

  async addMany(data: IndexedDBSchemas[T][]) {
    const objectStore = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key);

    const requests = data.map((item) => {
      return objectStore.add(JSON.parse(JSON.stringify(item)));
    });

    await Promise.all(requests.map((req) => Cache.onRequest(req)));

    return requests.map((req) => req.result) as string[];
  }

  async upsert(data: IndexedDBSchemas[T]) {
    const request = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key)
      .put(JSON.parse(JSON.stringify(data)));

    await Cache.onRequest(request);

    return request.result as string;
  }

  async get(id: string) {
    const request = this.db
      .transaction([this.key], "readonly")
      .objectStore(this.key)
      .get(id);
    await Cache.onRequest(request);

    return request.result as IndexedDBSchemas[T];
  }

  async getAll() {
    const request = this.db
      .transaction([this.key], "readonly")
      .objectStore(this.key)
      .getAll();

    await Cache.onRequest(request);

    return request.result as IndexedDBSchemas[T][];
  }

  async delete(id: string) {
    const request = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key)
      .delete(id);

    await Cache.onRequest(request);
  }

  async deleteMany(ids: string[]) {
    const objectStore = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key);

    const requests = ids.map((id) => {
      return objectStore.delete(id);
    });

    await Promise.all(requests.map((req) => Cache.onRequest(req)));
  }

  async getAllKeys() {
    const request = this.db
      .transaction([this.key], "readonly")
      .objectStore(this.key)
      .getAllKeys();

    await Cache.onRequest(request);

    return request.result as string[];
  }

  async updateOne(id: string, data: Partial<IndexedDBSchemas[T]>) {
    const objectStore = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key);

    const requestGet = objectStore.get(id);

    await Cache.onRequest(requestGet);

    if (!requestGet.result) return;

    const updatedUser = { ...requestGet.result, ...data };

    const requestPut = objectStore.put(updatedUser);

    await Cache.onRequest(requestPut);

    return requestPut.result as string;
  }

  async clear() {
    const request = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key)
      .clear();

    await Cache.onRequest(request);
  }
}

class CacheSingle<T extends SchemaKeys> {
  private static instances: Record<string, CacheSingle<any>> = {};
  private key: T;
  private db: IDBDatabase;
  private autoIncrement: boolean;
  private inlineKey?: string | number;

  private constructor(
    key: T,
    database: IDBDatabase,
    autoIncrement?: boolean,
    inlineKey?: string | number
  ) {
    this.key = key;
    this.db = database;
    this.autoIncrement = !!autoIncrement;
    this.inlineKey = inlineKey;
  }

  static getInstance<T extends SchemaKeys>(
    key: T,
    database: IDBDatabase,
    autoIncrement?: boolean,
    inlineKey?: string | number
  ): CacheSingle<T> {
    if (!CacheSingle.instances[key]) {
      CacheSingle.instances[key] = new CacheSingle(
        key,
        database,
        autoIncrement,
        inlineKey
      );
    }
    return CacheSingle.instances[key] as CacheSingle<T>;
  }

  async save(id: string, data: IndexedDBSchemas[T]) {
    // check if there exists a settings, only one settings to be saved
    const objectStore = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key);

    const requestGet = objectStore.getAllKeys();

    await Cache.onRequest(requestGet);

    const dataKey = this.autoIncrement ? Number(id) : id;

    if (requestGet.result.length && requestGet.result[0] !== dataKey) {
      throw new Error("There is already an exist entry");
    }

    const requestAdd = objectStore.put(
      data,
      this.autoIncrement ? dataKey : undefined
    );

    await Cache.onRequest(requestAdd);

    return requestAdd.result as string;
  }

  async get() {
    const request = this.db
      .transaction([this.key], "readonly")
      .objectStore(this.key)
      .getAll();
    await Cache.onRequest(request);

    return (request.result?.[0] as IndexedDBSchemas[T]) ?? undefined;
  }

  async updateProperty<K extends keyof IndexedDBSchemas[T]>(
    key: K,
    data: IndexedDBSchemas[T][K]
  ) {
    const objectStore = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key);

    const requestGet = objectStore.getAll();

    await Cache.onRequest(requestGet);

    if (!requestGet.result?.length) return;

    const updatedData = { ...requestGet.result[0], [key]: data };

    const requestPut = objectStore.put(updatedData, this.inlineKey);

    await Cache.onRequest(requestPut);

    return requestPut.result as string;
  }

  async clear() {
    const request = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key)
      .clear();

    await Cache.onRequest(request);
  }
}

export const useIndexDB = () => {
  const { $db } = useNuxtApp();
  return {
    db: $db,
    onRequest: Cache.onRequest,
    cache: {
      users: Cache.getInstance("users", $db),
      products: Cache.getInstance("products", $db),
      categories: Cache.getInstance("productCategories", $db),
      contacts: Cache.getInstance("contacts", $db),
      invoices: Cache.getInstance("invoices", $db),
      settings: CacheSingle.getInstance("settings", $db),
      terminal: CacheSingle.getInstance("terminal", $db, true, 1),
      pos: CacheSingle.getInstance("pos", $db),
      pendingContacts: Cache.getInstance("pendingContacts", $db),
      pendingInvoices: Cache.getInstance("pendingInvoices", $db),
      dumpInvoices: Cache.getInstance("dumpInvoices", $db),
      pricingPoliciesProducts: Cache.getInstance(
        "pricingPoliciesProducts",
        $db
      ),
    },
  };
};
