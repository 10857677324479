import { TRPCClientError } from "@trpc/client";
import { Buffer } from "buffer/";

export const generateReference = ({
  referenceData,
  transactionDate,
  keyPath,
}: {
  referenceData: {
    [key: string]: string | number | boolean;
  };
  transactionDate: string;
  keyPath: "posReceipts" | "posInvoices";
}) => {
  const counter = Number(referenceData[`${keyPath}ReferenceCounter`]);

  const prefix =
    <string>referenceData.posReferencePrefix +
    referenceData[`${keyPath}ReferencePrefix`];

  const isYearPrefixed = referenceData[`${keyPath}ReferenceIsYearPrefixed`];

  const year = transactionDate
    ? transactionDate.substring(0, 4)
    : new Date().toISOString().substring(0, 4);

  return isYearPrefixed ? `${year}-${prefix}${counter}` : `${prefix}${counter}`;
};

const isNetworkError = (error?: unknown): boolean => {
  if (!error) return false;

  // Check if the error is an instance of TRPCClientError
  if (error instanceof TRPCClientError) {
    // Check for specific network error messages or codes
    const networkErrorMessages = [
      "NetworkError",
      "Failed to fetch",
      "Network request failed",
      "TypeError: Failed to fetch",
    ];

    return networkErrorMessages.some((msg) => error.message.includes(msg));
  }

  return false;
};

const getTLVForValue = ({ tag, value }: { tag: number; value: string }) => {
  // @ts-expect-error: Buffer.from with 'utf8' encoding is used for legacy implementation
  const tagNumBuf = Buffer.from([tag], `utf8`);
  const tagValueBuf = Buffer.from(value, `utf8`);
  // @ts-expect-error: Buffer.from with 'utf8' encoding is used for legacy implementation
  const tagLengthBuf = Buffer.from([tagValueBuf.length], `utf8`);

  // build array
  const buffersArray = [tagNumBuf, tagLengthBuf, tagValueBuf];

  // return combined buffer
  return Buffer.concat(buffersArray);
};

export const buildQrCodeBase64 = (qrCodeArguments: string[]) => {
  const TLVBuffersArray: Buffer[] = [];
  for (const [index, arg] of qrCodeArguments.entries()) {
    const argTLVBuffer = getTLVForValue({
      tag: index + 1,
      value: arg,
    });
    TLVBuffersArray.push(argTLVBuffer);
  }

  const qrCodeBuffer = Buffer.concat(TLVBuffersArray);
  const qrCodeBase64 = qrCodeBuffer.toString("base64");

  return qrCodeBase64;
};

export const useUtils = () => {
  return {
    generateReference,
    isNetworkError,
    buildQrCodeBase64,
  };
};
