import { default as indexHq4cc2VrK5Meta } from "/home/runner/work/mezan/mezan/apps/pos/pages/dashboard/account/index.vue?macro=true";
import { default as setup_45pinrfRgax7l7SMeta } from "/home/runner/work/mezan/mezan/apps/pos/pages/dashboard/account/setup-pin.vue?macro=true";
import { default as accountPeE2BfvIteMeta } from "/home/runner/work/mezan/mezan/apps/pos/pages/dashboard/account.vue?macro=true";
import { default as indexHFb9oZFe0WMeta } from "/home/runner/work/mezan/mezan/apps/pos/pages/dashboard/cash-position/index.vue?macro=true";
import { default as indexDzIsUkxSQFMeta } from "/home/runner/work/mezan/mezan/apps/pos/pages/dashboard/index.vue?macro=true";
import { default as indexgchqCn6RMHMeta } from "/home/runner/work/mezan/mezan/apps/pos/pages/dashboard/invoices/index.vue?macro=true";
import { default as indexGTdOJKzvi5Meta } from "/home/runner/work/mezan/mezan/apps/pos/pages/dashboard/settings/index.vue?macro=true";
import { default as dashboardkWzJ0YQzAmMeta } from "/home/runner/work/mezan/mezan/apps/pos/pages/dashboard.vue?macro=true";
import { default as index7Bvyi1iOzdMeta } from "/home/runner/work/mezan/mezan/apps/pos/pages/index.vue?macro=true";
import { default as indexxz6mXx3AlCMeta } from "/home/runner/work/mezan/mezan/apps/pos/pages/print/index.vue?macro=true";
import { default as _91orgId_93OyqdYsNFUcMeta } from "/home/runner/work/mezan/mezan/apps/pos/pages/setup/[orgId].vue?macro=true";
import { default as indexRGebscoLPtMeta } from "/home/runner/work/mezan/mezan/apps/pos/pages/setup/index.vue?macro=true";
import { default as setupW57B9YFZSJMeta } from "/home/runner/work/mezan/mezan/apps/pos/pages/setup.vue?macro=true";
export default [
  {
    name: dashboardkWzJ0YQzAmMeta?.name,
    path: "/dashboard",
    meta: dashboardkWzJ0YQzAmMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/pos/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: accountPeE2BfvIteMeta?.name,
    path: "account",
    component: () => import("/home/runner/work/mezan/mezan/apps/pos/pages/dashboard/account.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-account",
    path: "",
    component: () => import("/home/runner/work/mezan/mezan/apps/pos/pages/dashboard/account/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-account-setup-pin",
    path: "setup-pin",
    component: () => import("/home/runner/work/mezan/mezan/apps/pos/pages/dashboard/account/setup-pin.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-cash-position",
    path: "cash-position",
    component: () => import("/home/runner/work/mezan/mezan/apps/pos/pages/dashboard/cash-position/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "",
    component: () => import("/home/runner/work/mezan/mezan/apps/pos/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-invoices",
    path: "invoices",
    component: () => import("/home/runner/work/mezan/mezan/apps/pos/pages/dashboard/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings",
    path: "settings",
    component: () => import("/home/runner/work/mezan/mezan/apps/pos/pages/dashboard/settings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/mezan/mezan/apps/pos/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "print",
    path: "/print",
    meta: indexxz6mXx3AlCMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/pos/pages/print/index.vue").then(m => m.default || m)
  },
  {
    name: setupW57B9YFZSJMeta?.name,
    path: "/setup",
    component: () => import("/home/runner/work/mezan/mezan/apps/pos/pages/setup.vue").then(m => m.default || m),
    children: [
  {
    name: "setup-orgId",
    path: ":orgId()",
    component: () => import("/home/runner/work/mezan/mezan/apps/pos/pages/setup/[orgId].vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "",
    component: () => import("/home/runner/work/mezan/mezan/apps/pos/pages/setup/index.vue").then(m => m.default || m)
  }
]
  }
]